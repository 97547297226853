<template>
  <deactivation>
    <TitleWrapper :filter-option="true" title="LTV_RB_H" :display-breadcrumb="true">
      <template v-slot:filterItems>
        <h2 class="mb-3 text-text-color font-roboto font-normal text-xl">Search</h2>
        <InputFieldWrapper layout-mode="true">
          <UiSingleSelect
            title="Class"
            :options="options"
            :class="$style.widthItems"
            class="flex-1"
          />
          <UiSingleSelect
            title="Section"
            :options="options"
            :class="$style.widthItems"
            class="flex-1"
          />
          <UiSingleSelect
            title="Student Id"
            :options="options"
            :class="$style.widthItems"
            class="flex-1"
          />
          <UiSingleSelect
            title="Student Name"
            :options="options"
            :class="$style.widthItems"
            class="flex-1"
          />
          <UiSingleSelect
            title="P_STATUS"
            :options="options"
            :class="$style.widthItems"
            class="flex-1"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
    <TableWrapper>
      <THead>
        <TRHead>
          <TH v-for="(head, index) in tableHeaders" :key="index">
            {{ head }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(type, index) in lessonTypes" :key="index">
          <TD>
            {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ type.description }}
          </TD>
          <TD>
            <TableAction :idx="index" :action-list="actionList" />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <!-- <ValidationObserver tag="div"> -->
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="showModal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Add Lesson Type</span>
      </template>
      <template v-slot>
        <div class="py-6 px-6">
          <div class="flex flex-col">
            <div class="">
              <InputBox
                type="text"
                name="LTV_RB_H"
                title="LTV_RB_H"
                label="LTV_RB_H"
                placeholder="LTV_RB_H"
                :class="$style.widthItems"
                class="flex-1 w-full"
                rules="required"
              />
            </div>
            <InputBox
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
              :class="$style.widthItems"
              class="flex-1 w-full"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
          @click="handleClick('close')"
        >
          Cancel
        </button>
        <button
          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        >
          <span v-if="isLoading">
            <Loader />
          </span>
          <span v-else>
            <span>Add</span>
          </span>
        </button>
      </template>
    </UiModalContainer>
    <!-- </ValidationObserver> -->
  </deactivation>
</template>

<script>
import InputBox from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { dropdownTopBottom } from '@utils/generalUtil.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    TitleWrapper,
    UiModalContainer,
    InputBox,
    TableAction,
    InputFieldWrapper,
    // ValidationObserver,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    Loader,
    UiSingleSelect,
  },
  mixins: [scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      options: ['Select'],
      isActive: false,
      isOpen: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      lessonTypes: [{ title: 'Theory', description: 'Here is the description' }],
      tableHeaders: ['Name', 'Description', 'Actions'],
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      showUiModal: (state) => state.layout.showUiModal,
      isLoading: (state) => state.layout.isLoading,
    }),
  },

  mounted() {
    this.setRightbarData()
  },
  methods: {
    dropdownTopBottom,
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal and resetForm method on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          break
        case 'close':
          this.setShowModal(false)
          break
        case 'cancel':
          break
        case 'confirm':
          break
      }
    },
    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'LTV_RB_H',
          buttons: [
            {
              title: 'LTV_RB_BT',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_LESSON_TYPES',
                value: 0,
                tooltipDescription: 'Total no. of lesson types',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}

tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
